<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="validator" slim>
    <form
      name="contact"
      class="form"
      ref="form"
      @submit.prevent="handleSubmit(submit)"
    >
      <Input
        v-model="name"
        class="contact-input"
        label="nombre"
        name="name"
        type="text"
        rules="required"
      />
      <Input
        v-model="company"
        class="contact-input"
        label="empresa"
        name="company"
        type="text"
        rules="required"
      />
      <Input
        v-model="email"
        class="contact-input"
        label="e-mail"
        name="email"
        type="email"
        rules="required|email"
      />
      <Textarea
        v-model="message"
        class="contact-input"
        label="mensaje"
        name="message"
        rules="required"
      />
      <Button type="submit" text="enviar" solid />
    </form>
  </ValidationObserver>
</template>

<script>
import emailjs from 'emailjs-com'
import { ValidationObserver } from 'vee-validate'
import Toast from '@/utils/toast'
import Input from '@/components/form/Input'
import Textarea from '@/components/form/Textarea'
import Button from '@/components/Button'

export default {
  name: 'ContactForm',
  components: { ValidationObserver, Input, Textarea, Button },
  data () {
    return {
      name: '',
      company: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit () {
      try {
        emailjs.sendForm(
          'andinor_contacto',
          'contact_form_andinor',
          this.$refs.form,
          'user_zciVlzrlmaBZZIeGksflz',
          {
            name: this.name,
            company: this.company,
            email: this.email,
            message: this.message
          }
        )
        Toast.success('Mensaje enviado.')
        this.$refs.validator.reset()
        this.name = ''
        this.email = ''
        this.company = ''
        this.message = ''
      } catch (error) {
        console.log(error)
        Toast.error(error.message)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.form {
  @apply flex flex-col justify-between items-center w-full;
}
.contact-input {
  @apply w-full mb-6;
}
</style>
