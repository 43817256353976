<template>
  <button :class="{ solid, white }" class="button" @click="handleClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: { type: String, required: true },
    solid: Boolean,
    white: Boolean,
    link: { type: String, required: false }
  },
  methods: {
    handleClick () {
      if (this.link) window.location.href = this.link
    }
  }
}
</script>

<style lang="postcss" scoped>
.button {
  @apply border border-brand py-3 px-14 bg-transparent;
  @apply text-brand text-2xl font-light lowercase;
  @apply transition-all duration-200 ease-linear;
  @apply hover:bg-brand hover:text-white;
}
.button.solid {
  @apply bg-brand text-white font-bold uppercase;
  @apply hover:bg-transparent hover:text-brand;
}
.button.white {
  @apply border-white text-white;
  @apply hover:bg-white hover:text-brand;
}
</style>
