<template>
  <span :class="{ active }" class="label">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Label',
  props: {
    text: {
      type: String,
      required: true
    },
    active: Boolean
  }
}
</script>

<style lang="postcss" scoped>
  .label {
    @apply bg-white text-brand text-lg font-light lowercase;
    @apply absolute block top-2 left-3 px-1;
    @apply transition-all duration-200 ease-in-out;
  }
  .label.active {
    @apply -top-2 text-sm leading-4;
  }
</style>
