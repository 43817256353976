import Noty from 'noty'

export default {
  info: toastMaker('info'),
  error: toastMaker('error'),
  success: toastMaker('success')
}

function toastMaker (type) {
  return text => {
    new Noty({
      type,
      text,
      timeout: 4000,
      theme: 'andinor'
    }).show()
  }
}
