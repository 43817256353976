<template>
  <ValidationProvider v-slot="{ errors, failed }" :rules="rules" slim>
    <label :class="['input-wrapper', { hasError: failed }]">
      <input
        :value="value"
        :name="name"
        class="input"
        @input="$emit('input', $event.target.value)"
        @focus="activateLabel('focus')"
        @blur="activateLabel('blur')"
      />
      <Label :active="activeLabel" :text="label">{{ label }}</Label>
      <transition name="fade">
        <span v-if="failed" class="error">
          {{ errors[0] }}
        </span>
      </transition>
    </label>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Label from './Label'

export default {
  name: 'Input',
  components: { ValidationProvider, Label },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeLabel: !!this.value
    }
  },
  methods: {
    activateLabel (type) {
      if (type === 'focus') this.activeLabel = true
      if (type === 'blur' && !this.value) this.activeLabel = false
    }
  }
}
</script>

<style lang="postcss" scoped>
  .input-wrapper {
    @apply relative block h-11;
  }
  .input {
    @apply w-full h-11 border border-brand outline-none px-4;
    @apply transition-all duration-200 ease-linear;
  }
  .input:focus {
    @apply border-secondary;
  }
  .input-wrapper .error {
    @apply absolute -bottom-5 right-0 text-xs text-red-500;
  }
</style>
